<template>
    <div class="col s12 m6 grey darken-2 left-align">
        <h5 class="orange-text center">Resolver</h5>
        <br>
        <form>
            <div class="input-field">
                <select ref="winner" class="browser-default">
                    <option value="null">Seleccionar Ganador</option>
                    <option :value="data.seller"> {{ data.seller }} - Vendedor</option>
                    <option :value="data.buyer"> {{ data.buyer }}- Comprador</option>
                </select>
            </div>

            <div class="input-field bottom">
                <input type="password" placeholder="Password" v-model="password">
            </div>

            <div class="col s12 center">
                <Error :warning="error" v-if="error" />
                <Loader v-if="isLoading" />
            </div>

            <div class="col s12 center">
                <LargeButton 
                    color="orange" 
                    icon="fas fa-check" 
                    text="Resolver" 
                    position="left" 
                    @click.prevent="resolve"
                />
            </div>

        </form>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import LargeButton from '@/components/UI/Buttons/LargeButton';
import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import { post } from '@/composables/httpCalls';
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    props: ['data'],
    components: { LargeButton, Loader, Error },
    
    setup(props){
        
        const {isLoading, error } = useBoilerplate(false);
        
        const winner = ref(null);
        const password = ref('');
        
        const resolve = async () => {
            
            const resolution = winner.value.options[winner.value.selectedIndex].value;
            
            if(password.value == '' || resolution == 'null'){
                return;
            }
            
            error.value = null;
            isLoading.value = true;
            
            const url = "/api/resolve-dispute";
            
            const vars = {
                pw: password.value,
                winner: resolution,
                dispute: props.data.dispute._id,
                escrow: props.data.dispute.compraventa
            }
            
            try{

                const res = await post(url, vars);

                if(res.status == 200){
                    M.toast({html: "Disputa Resuelta"});
                    props.data.dispute.open = false;
                    props.data.dispute.resolution = resolution;
                    password.value = '';
                    document.querySelector('form').reset();
                }else if(res.status == 401){
                    error.value = "Password Incorrecta"
                }else{
                    error.value = "Error!";
                }

                isLoading.value = false;
            
            }catch(err){
                console.log(err);
                error.value = err;
                isLoading.value = false;
            }

        }

        return { isLoading, error, resolve, password, winner };
    }
}
</script>

<style scoped>
    .bottom{margin-bottom: 40px}
    form{ 
        padding-left: 5%;
        padding-right: 5%;
    }
</style>