<template>
  <div class="container">
    <FloatingButton icon="fas fa-home" @click="goBack"/>
    <LoadingFullPage v-if="isLoading"/>
    <div class="row" v-else>
        
        <Title text="Disputa" />

        <div class="col s12 grey darken-2 frame">
            <div class="col s12 m6 white left-align grey-text text-darken-2">
                <h5 class="orange-text center">Datos:</h5>
                <br>
                <p><span class = "orange-text">#ID:</span> {{ id }}</p>
                
                <p>
                    <span class = "orange-text">Comprador:</span> 
                    <span class="blue-text" @click="triggerReputation('buyer')">{{ data.buyer }}</span>
                </p>

                <p>
                    <span class = "orange-text">Vendedor:</span> 
                    <span class = "blue-text" @click="triggerReputation('seller')">{{ data.seller }}</span>
                </p>
                
                <p><span class = "orange-text">Abierta:</span> {{ data.dispute.open }}</p>
                <p><span class = "orange-text">Abierta Por:</span> {{ data.dispute.actor }}</p>
                <p><span class = "orange-text">Mensaje:</span> {{ data.dispute.message }}</p>
                
                <p>
                    <router-link class = "blue-text" :to="{name: 'Auth'}">
                        Revisa Compraventa
                    </router-link>
                </p>
                <p v-if="data.chat != null">
                    <router-link class = "blue-text" :to="{name: 'Chat', params: {room: data.chat}}">
                        Revisa Chat
                    </router-link>
                </p>

                <p v-if="data.dispute.resolution != null" class = "winner">
                    <span class = "orange-text">Ganador:</span> {{ data.dispute.resolution }}
                </p>

            </div>

            <DisputeResolutionForm :data="data" />
        </div>
        

    </div>
  </div>

    <teleport to="body">
        <ReputationModal :isActive="isActive" :user="userToBeChecked" @close="isActive=false"/>
    </teleport>

</template>

<script>
import { ref } from '@vue/reactivity';
import Title from '@/components/Admin/Title'
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import DisputeResolutionForm from '@/components/Dispute/DisputeResolutionForm'
import ReputationModal from '@/components/modals/ReputationModal' 
import { onMounted } from '@vue/runtime-core';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    props: ['id'],
    components: { DisputeResolutionForm, Title, FloatingButton, LoadingFullPage, ReputationModal },
    setup(props){
        
        const { router, isActive, isLoading } = useBoilerplate(true);

        const data = ref(null);
        const userToBeChecked = ref('');

        const goBack = () => router.push({name: "AdminPanel"});

        const triggerReputation = role => {
            userToBeChecked.value = data.value[role];
            isActive.value=true;
        }
        
        onMounted(async() => {
            try {
                const url = '/api/getDispute/' + props.id;
                const res = await fetch(url, {cache: "no-cache"});
                data.value = await res.json();
                isLoading.value = false;
            } catch (err) {
                console.log(err);
                isLoading.value = false;
            }
        })
        return { isLoading, goBack, data, isActive, userToBeChecked, triggerReputation };
    }
}
</script>

<style scoped>
    .winner{
        font-weight: bold;
    }
    .frame{
        padding: 20px;
    }
    .blue-text{
        cursor: pointer;
    }
</style>